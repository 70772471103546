export function getChatWidgetCode(companyHash, jobHash = null) {
  let jobHashLine = '';

  if (jobHash) {
    jobHashLine = `\t\tjobHash: '${jobHash}',\n`;
  }

  return (
    '<link rel="stylesheet" href="https://app.hrblade.com/w/style.css">\n' +
    '<script src="https://app.hrblade.com/w/chat-widget.umd.js"></script>\n' +
    '<script>\n' +
    '\twindow.initChatWidget({\n' +
    `\t\tcompanyHash: '${companyHash}',\n` +
    jobHashLine +
    `\t\tcolor: '', // Optional\n` +
    `\t\tbuttonText: '', // Optional\n` +
    '\t})\n' +
    '</script>'
  );
}

export function copyText(text) {
  if (!navigator.clipboard) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  } else {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error(err);
    });
  }
}
