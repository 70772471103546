<template>
  <div class="integrations">
    <!-- API KEY -->
    <card class="api-key" :card-title="$t('integrations.api_key.title')">
      <div class="integrations-description">
        {{ $t('integrations.api_key.description') }}
      </div>

      <div class="api-key-label">{{ $t('integrations.api_key.label') }}</div>
      <div class="api-key-value">
        <a-input class="api-key-input" size="small" :value="token" readonly />
        <button class="api-key-copy-button" @click="copyToken">
          <icon-copy class="api-key-copy-icon" />
        </button>
      </div>

      <a-popconfirm
        :title="`${$t('notify.generate_new_token_notify')}`"
        @confirm="handleGenerateToken"
      >
        <app-button
          class="integrations-button"
          type="primary"
          button-size="medium"
          :loading="tokenGenerationLoading"
        >
          {{ $t('get_api_token') }}
        </app-button>
      </a-popconfirm>
    </card>

    <!-- Zapier -->
    <card class="zapier" :card-title="$t('integrations.zapier.title')">
      <div class="integrations-description">
        {{ $t('integrations.zapier.description') }}
      </div>

      <app-button
        tag="a"
        href="https://zapier.com/apps/hrblade/integrations"
        target="_blank"
        class="integrations-button"
        type="primary"
        button-size="medium"
      >
        {{ $t('connect') }}
      </app-button>
    </card>
  </div>
</template>

<script>
import Card from './Card';
import AppButton from './AppButton';
import IconCopy from './icons/Copy';
import apiRequest from '@/js/helpers/apiRequest';
import { copyText } from '@/utils/common';

export default {
  name: 'Integrations',

  components: {
    Card,
    IconCopy,
    AppButton
  },

  data() {
    return {
      token: '',
      tokenGenerationLoading: false
    };
  },

  methods: {
    async handleGenerateToken() {
      try {
        this.tokenGenerationLoading = true;
        const res = await apiRequest('create/token', 'POST', null, true);

        const { error } = res;

        if (!error) {
          this.token = res.response.data;
          this.$notification.error({
            message: this.$t('notify.api_key_generated'),
            icon: () => <icon-error class="success-icon" />
          });
        }
      } catch (error) {
        console.error(error);

        this.$notification.error({
          message: this.$t('notify.error'),
          description: this.$t('notify.something_went_wrong'),
          icon: () => <icon-error class="error-icon" />
        });
      } finally {
        this.tokenGenerationLoading = false;
      }
    },

    copyToken() {
      if (!this.token) {
        return;
      }

      copyText(this.token);

      this.$notification.success({
        message: this.$t('copied'),
        icon: () => <icon-success class="success-icon" />
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.integrations {
  & > *:not(:last-child) {
    margin-bottom: 28px;
  }

  &-description {
    font-size: 12px;
    max-width: 573px;
    margin-bottom: 10px;
  }

  &-button {
    margin-top: 20px;
  }
}

.api-key {
  color: #000;

  &-label {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  &-value {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 600px;
  }

  &-input {
    flex-grow: 1;
  }

  &-copy-button {
    cursor: pointer;
    padding: 0;
    border: none;
    background: none;

    &:active {
      transform: translateY(1px);
    }
  }

  &-copy-icon {
    display: block;
    width: 24px;
    height: 24px;
  }
}
</style>
